
<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 成果推荐----    成果详情 -->
    <Achievement-DetailsNel></Achievement-DetailsNel>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import AchievementDetailsNel from "./base/Achievement_detailsNel";
export default {
  name: "Achievement_details",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
       this.ConList = item;
    },
  },
  components: {
    ConTab,
    AchievementDetailsNel,
  },
};
</script>

<style scoped>
</style>
