 <template>
  <div class="Achievement_details">
    <!-- 成果详情 -->
    <div class="pea-title">
      <div class="one-content">
        <div class="copy-title">
          <div class="copy-con copy-con1">
            <span></span>
            <h3>成果详情</h3>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="bot-box-details">
      <div class="bot-details w">
        <div class="bot-title">{{ cg.name }}</div>
        <div class="each-detail-list">
          <span class="lf-tab">
            <i class="el-icon-time"></i>
            <span class="tab-text">{{ cg.created_at | formatDate }}</span></span
          >
          <span class="lf-tab lf-tab-rg">
            <i class="el-icon-view"></i>
            <span class="tab-text">{{ cg.views }}</span></span
          >
        </div>

        <div class="accept-btn" style="" @click="centerDialogVisible = true">
          寻求合作
        </div>
      </div>
    </div>
    <div class="basic-table w">
      <ul>
        <li class="each-grid-table" v-for="(item, index) in info" :key="index">
          <h3 class="type-name">{{ item.title }}</h3>
          <p class="type-bottom">{{ item.text }}</p>
        </li>
      </ul>
    </div>
    <div class="wrapPolist w">
      <div class="each-type">
        <h3 class="type-name">项目简介</h3>
        <p class="type-bottom">
          {{ cg.project_say }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">技术/产品创新性</h3>
        <p class="type-bottom">
          {{ cg.technology }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">知识产权情况</h3>
        <p class="type-bottom">
          {{ cg.knowledge }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">行业情况</h3>
        <p class="type-bottom">
          {{ cg.situation }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">市场情况</h3>
        <p class="type-bottom">
          {{ cg.market }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">项目团队情况</h3>
        <p class="type-bottom">
          {{ cg.team }}
        </p>
      </div>
      <div class="each-type">
        <h3 class="type-name">合作意向</h3>
        <p class="type-bottom">
          {{ cg.cooperation }}
        </p>
      </div>
    </div>

    <el-dialog
      title="寻求合作"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="联系人姓名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="我的联系方式">
          <el-input v-model="form.phone" maxlength="11"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="hz">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { AchievementsXq, HZ } from "@/common/js/api";
import { formatDate } from "../../../common/js/times"; //时间

export default {
  name: "Achievement_detailsNel",
  data() {
    return {
      id: "",
      info: [],
      cg: {},
      centerDialogVisible: false,
      form: {
        phone: "",
        name: "",
      },
    };
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getAxq();
  },
  methods: {
    // 获取成果详情
    getAxq() {
      AchievementsXq({
        id: this.id,
      })
        .then((res) => {
          console.log(res);
          this.cg = res.data;
          if (this.cg.industry == '') {
            this.cg.industry = "无";
          } else {
            this.cg.industry = this.cg.industry.name;
          }
          if (this.cg.xtype == '') {
            this.cg.xtype = "无";
          } else {
            this.cg.xtype = this.cg.xtype.name;
          }
          if (this.cg.territory == '') {
            this.cg.territory = "无";
          } else {
            this.cg.territory = this.cg.territory.name;
          }
          this.info.push(
            {
              title: "现有持有人",
              text: this.cg.holder,
            },
            {
              title: "工作单位",
              text: this.cg.work,
            },
            {
              title: "战略性新兴产业分类",
              text: this.cg.xtype,
            },
            {
              title: "项目来源",
              text: this.cg.projecty,
            },
            {
              title: "手机",
              text: this.cg.phone,
            },
            {
              title: "行业分类",
              text: this.cg.industry,
            },
            {
              title: "技术领域",
              text: this.cg.territory,
            },
            {
              title: "项目所处阶段",
              text: this.cg.projects,
            }
          );
        })
        .catch(() => {});
    },
    // 寻求合作
    hz() {
      if (
        this.form.phone != null &&
        this.form.phone != "" &&
        /^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
          this.form.phone
        )
      ) {
        HZ({
          member_id: JSON.parse(localStorage.getItem("user")).id,
          achieve_id: this.id,
          phone: this.form.phone,
          name: this.form.name,
        })
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.centerDialogVisible = false;
              this.$message.success("发送成功");
            }
          })
          .catch(() => {});
      } else {
        this.$message.error("请输入正确的11位手机号");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Achievement_details {
  background-color: #f8f9fa;
  padding-top: 10px;
  padding-bottom: 60px;
  margin-top: 135px;
  .bot-box-details {
    background-color: #407ce9;
    // background: url(../../../assets/img/qiyeyun/cgxq.png);
    font-size: 20px;
    color: #fff;
    .bot-details {
      position: relative;
      padding: 60px 0 59px;
      margin: auto;
      min-height: 216px;
      .bot-title {
        font-size: 28px;
        max-width: 910px;
        font-weight: bolder;
        text-align: justify;
      }
      .each-detail-list {
        margin-top: 25px;
        i {
          margin-right: 8px;
        }
        .lf-tab-rg {
          margin-left: 40px;
        }
      }
      .each-detail-listtab {
        margin-top: 41px;
        .each-tab-label {
          padding: 3px 12px;
          background: #ffffff;
          border-radius: 3px;
          color: rgba(56, 146, 235, 100);
          font-size: 12px;
          margin-right: 12px;
        }
      }
      .accept-btn {
        position: absolute;
        width: 138px;
        height: 44px;
        background: #fff;
        border-radius: 4px;
        right: 0;
        bottom: 50%;
        transform: translateY(50%);
        color: #3892eb;
        display: block;
        text-align: center;
        line-height: 44px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .basic-table {
    margin: 19px auto 26px;
    border: 3px solid rgba(56, 146, 235, 100);
    border-radius: 5px;
    padding: 21px 23px 28px 22px;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .each-grid-table:nth-of-type(n + 5) {
        border-bottom: 0;
      }
      .each-grid-table {
        flex: 25%;
        color: rgba(105, 105, 105, 100);
        font-size: 14px;
        font-family: SourceHanSansSC-regular;
        border-bottom: 1px solid #bbb;
        margin-top: 28px;
        .type-name {
          border-left: 0;
          padding-left: 23px;
        }
        .type-bottom {
          padding-left: 23px;
        }
      }
    }
  }
  .type-name {
    color: rgba(56, 146, 235, 100);
    font-size: 20px;
    font-family: SourceHanSansSC-bold;
    font-weight: 700;
    margin-bottom: 16px;
    border-left: 6px solid #3892eb;
    padding-left: 14px;
  }
  .type-bottom {
    color: rgba(105, 105, 105, 100);
    font-size: 14px;
    font-family: SourceHanSansSC-regular;
    padding-left: 14px;
    padding-bottom: 20px;
    line-height: 1.5;
  }
  .wrapPolist {
    background-color: #fff;
    padding: 38px 24px 43px;
    .each-type {
      padding: 22px 0;
      border-bottom: 1px dashed #bbb;
    }
  }
}
@media screen and (max-width: 1200px) {
	.Achievement_details{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.copy-con h3{
		font-size: 2.2rem;
	}
	.Achievement_details .bot-box-details .bot-details .bot-title{
		font-size: 1.7rem;
	}
	.Achievement_details .bot-box-details{
		font-size: 1.4rem;
	}
	.Achievement_details .bot-box-details .bot-details .accept-btn{
		width: 8rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.5rem;
	}
	.Achievement_details .type-name{
		font-size: 1.5rem;
	}
	.Achievement_details .type-bottom{
		font-size: 1.4rem;
	}
	/deep/.el-dialog{
		width: 60% !important;
	}
	/deep/.el-dialog__title{
		font-size: 1.4rem;
	}
}
</style>